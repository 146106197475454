<template>
  <section style="background-color: #f5f5f5; height: 100%">
    <div class="card-center elevation-6 d-flex flex-column">
      <div>
        <v-img
          class="ml-6 my-6"
          width="150"
          :src="require('../../../assets/images/logos/sociis-branco.png')"
        ></v-img>
      </div>
      <div class="d-flex flex-column align-center justify-center">
        <h1 class="white--text text-h4 font-weight-bold mb-3 mt-6 text-center">
          Calculadora de Absenteísmo
        </h1>
        <v-container>
          <p class="white--text text-subtitle-1 text-center mb-16">
            O
            <a
              href="https://sociisrh.com.br/absenteismo/"
              target="_blank"
              rel="noopener noreferrer"
              class="info--text text-decoration-none"
            >
              absenteísmo
            </a>
            é um dos
            <a
              href="https://sociisrh.com.br/indicadores-estrategicos-de-rh/"
              target="_blank"
              rel="noopener noreferrer"
              class="info--text text-decoration-none"
            >
              indicadores estratégicos
            </a>
            de RH porque mede o volume de faltas dos colaboradores. Trata-se de
            um elemento fundamental, já que um número muito alto de abstenções
            resulta em grande perda de produtividade e custos elevados. Também
            pode ser um indício da falta de engajamento dos colaboradores,
            resultante da baixa motivação e de um clima organizacional ruim.
          </p>
        </v-container>
      </div>
      <!-- Card -->
      <div class="d-flex align-center justify-center mb-6">
        <v-card min-width="50%" max-width="80%" class="px-3 py-1">
          <v-fade-transition group>
            <template v-if="!openResult">
              <div key="sectionForm">
                <v-card-title
                  tag="h2"
                  class="black--text font-weight-bold"
                  style="color: #36383f !important"
                >
                  Calcule a absenteísmo da sua empresa
                </v-card-title>
                <v-card-subtitle tag="h4" class="ml-4">
                  Preencha o formulário para saber a porcetagem de absenteísmo
                  da sua empresa.
                </v-card-subtitle>
                <v-card-text class="mt-10">
                  <FormAbsenteismo @result="showResult($event)" />
                </v-card-text>
              </div>
            </template>
            <template v-else>
              <div key="sectionResult">
                <Result
                  @return-calculate="openResult = false"
                  :value="result"
                  type="absenteismo"
                />
              </div>
            </template>
          </v-fade-transition>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import FormAbsenteismo from "../components/FormAbsenteismo.vue";
import Result from "../components/Result.vue";

export default {
  components: {
    FormAbsenteismo,
    Result,
  },
  data() {
    return {
      openResult: false,

      result: "",
    };
  },
  methods: {
    showResult(result) {
      this.result = result;
      this.openResult = true;
    },
  },
};
</script>

<style scoped>
.card-center {
  background-color: #177E89;
  height: 50%;
}

@media (max-width: 537px) {
  .card-center {
    height: 70%;
  }
}
</style>
