<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          type="number"
          label="Total de horas perdidas"
          prepend-inner-icon="mdi-timer-minus"
          :rules="rules.horasPerdidas"
          v-model="form.horasPerdidas"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          type="number"
          label="Total de horas que deveriam ser trabalhadas"
          prepend-inner-icon="mdi-timer"
          :rules="rules.horasTrabalhadas"
          v-model="form.horasTrabalhadas"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center justify-center">
          <v-btn
            @click="calculate()"
            color="#6DA34D"
            dark
            class="mt-10 white--text"
          >
            Calcular Absenteísmo
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "FormAbsenteismo",
  data() {
    return {
      valid: true,
      form: {
        horasPerdidas: 0,
        horasTrabalhadas: 0,
      },

      rules: {
        horasPerdidas: [
          (v) => !!v || "Total de horas perdidas é um campo obrigatório.",
        ],
        horasTrabalhadas: [
          (v) =>
            !!v ||
            "Total de horas que deveriam ser trabalhados é um campo obrigatório.",
        ],
      },
    };
  },
  methods: {
    calculate() {
      const validate = this.$refs.form.validate();

      if (validate == false) return;

      const result =
        (parseInt(this.form.horasPerdidas) /
          parseInt(this.form.horasTrabalhadas)) *
        100;

      this.$emit("result", result);
    },
  },
};
</script>

<style></style>
